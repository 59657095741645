










































import { Component, Vue, PropSync } from "vue-property-decorator";
import { DxDateBox } from "devextreme-vue";
@Component({ components: { DxDateBox } })
export default class PeriodoFechas extends Vue {
  @PropSync("desde", { default: new Date() }) public desdesync!: Date;
  @PropSync("hasta", { default: new Date() }) public hastasync!: Date;
  @PropSync("dialog_seleccion_fechas", { default: false })
  public dialog_seleccion_fechassync!: Date;
  public created() {}
}
